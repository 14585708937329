import './App.css';
import Home from './home/home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contactus from './contactus/contactus';
import Main from './main/main';
import Us from './us/us';
import Services from './services/services';
import Privacy from './privacy/privacy';
import Projects from './projects/projects';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { red, teal, grey } from '@mui/material/colors';

const defaultTheme = createTheme({

  components: {
    MuiGrid:{
      styleOverrides: {
        root: {
          padding: 0,
          paddingLeft:0
        },
      },
      variants: [
        {
          props: { variant: 'normal' },
          style: {
            padding:0,
            paddingLeft:0
          },
        },
      ]
    },
    MuiContainer:{
      variants: [
        {
          props: { variant: 'no-color' },
          style: {
            maxWidth:false,
            backgroundColor: grey[200],
            padding:56
          },
        },
      ]
    },

    MuiButton: {
      variants: [
        {
          props: { variant: 'special' },
          style: {
            color: "#ffb400 !important",
            border: "8px solid #ffb400",
            padding: "2em 2.5em",
            fontSize: "1em",
            fontWeight: "700",
            fontFamily: "inherit",
            backgroundColor: "transparent",
            borderRadius: "0px",
            cursor: "pointer",
            transition: "backgroundColor 0.25s",
            position: "relative",
            display: "inline-block",
            ":after": {
              //content: "\x0020",
              position: "absolute",
              marginleft: "0.5em",
              fontWeight: 400
            },
            ":hover::after": {
               //content: "\x2192",
                color:"#FFF"
            },
            ':hover': {
              backgroundColor: "#ffb400",
              color: "#fff !important",
            }
          },
        },
        {
          props: { variant: 'appbar' },
          style: {
            textTransform: 'none',
            border: `1px solid ${red[50]}`,
            fontStyle:"normal",
            borderRadius: 56,
            fontWeight:"bold"
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            height:56,
          },
        },
        {
          props: { variant: 'dashed', color: 'secondary' },
          style: {
            border: `4px dashed ${red[50]}`,
            fontStyle:"normal",
          },
        },
        {
          props: { variant: 'header' },
          style: {
            fontStyle:"normal",
            borderRadius: 56,
            textTransform: 'none',
            fontWeight:"bold"
          },
        },
      ],
    },

    MuiTextField: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            color: "#252525 !important",

            backgroundColor: "#fff",

          },
        },
      ],
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#FFF',
          '--TextField-brandBorderHoverColor': '#FFF',
          '--TextField-brandBorderFocusedColor': '#FFF',
          '& label.Mui-focused': {
            color: '#FFF',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#FFF',
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#FFF',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#FFF',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&::before, &::after': {
            borderBottom: '0px solid #FFF',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '0px solid #FFF',
          },
          '&.Mui-focused:after': {
            borderBottom: '0px solid #FFF',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&::before': {
            borderBottom: '0px solid #FFF',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '0px solid #FFF)',
          },
          '&.Mui-focused:after': {
            borderBottom: '0px solid #FFF',
          },
        },
      },
    },
  },
  palette: {
      primary: {
          light: red[900],
          main: red[900],
          dark:  red[900],
          contrastText: '#fff',
      },
      secondary: {
          light: red[900],
          main: teal[900],
          dark:  red[900],
          contrastText: '#000',
      },
  },
  typography: {
      title: {
          fontSize: 24,
          fontWeight: 800,
      },

      subtitle: {
          fontSize: 32,
          color: teal[400],
          align:"center"
      },
      subtitle1: {
        fontSize: 12,
      },
      body1: {
        fontWeight: 500,
      },
      button: {
        fontStyle: 'normal',
      },
    },
});

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
    <BrowserRouter>
      <Routes>
        <Route path="/contactus" element={<Main props={<Contactus/>}></Main>} />
        <Route path="/" element={<Main props={<Home/>}></Main>} />
        <Route path="/privacy" element={<Main props={<Privacy/>}></Main>} />
        <Route path="/services" element={<Main props={<Services/>}></Main>} />
        <Route path="/projects" element={<Main props={<Projects/>}></Main>} />
        <Route path="/us" element={<Main props={<Us/>}></Main>} />
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
