import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Stack, Divider } from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://xpendiente.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

export default function Contactus() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <Container maxWidth={false} disableGutters
    sx={{
        backgroundImage: "radial-gradient(circle at top left, rgba(40, 40, 40, 0.79) 0%, rgba(40, 40, 40, 0.86) 100%), url('https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/image_hero.webp?alt=media&token=3e1d6b4b-b54a-48e7-b612-828e2bf36079') !important",
        pt:24,
        pb:24,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        
        }}
    >
    <Grid container alignItems="center" justifyContent="center" component="main"> 
      <Grid item xs={12} sm={12} md={6} >   
      <Stack spacing={2} sx={{padding:8}}>
          <Typography variant="h4" align="left" color="white" fontWeight="bolder">
              Contactanos
            </Typography>
                       
          <Divider flexItem sx={{ borderColor:"#fff", borderBottomWidth: '10px' ,backgroundColor:"#fff", maxWidth:"100px"}} />

          <Typography variant="body1" gutterBottom color="white" s>
          Teléfono: 221 841 0015
            </Typography>
            <Typography variant="body1" gutterBottom color="white" s>
          Correo: todofixpuebla@gmail.com
            </Typography>
            <Typography variant="body1" gutterBottom color="white" s>
            Horario de atención Lunes a Viernes de 9AM a 6PM, Sábados 9AM a 2PM
            </Typography>
            
            
      </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={6 } sx={{backgroundColor:"#252525"}}>
            <Stack spacing={2} sx={{padding:8}}>
              <Typography variant="h4" align="left" color="white" fontWeight="bolder">
                Envianos un mail
              </Typography>
              <Divider flexItem sx={{ borderColor:"#fff", borderBottomWidth: '10px' ,backgroundColor:"#fff", maxWidth:"100px"}} />
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="telefono"
                  label="Telefono"
                  name="telefono"
                  autoComplete="telephone"
                   variant="filled"
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="message"
                  label="Message"
                  type="message"
                  id="message"     
                  multiline
                  rows={4}
                   variant="filled"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Enviar
                </Button>
              </Box>
            </Stack>
          </Grid>
    </Grid>
  </Container> 
  );
}